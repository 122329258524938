<template>
	<div v-if="searchActive || filtersActive" class="overview-applied-filters">
		<span class="title">Gekozen filters:</span>

		<AppliedFilterBadge
				v-if="searchActive"
				:text="options.search.value"
				@delete="options.search.value = ''"
		/>

		<template v-for="filter in options.filters">
			<AppliedFilterBadge
					v-if="filter.type === 'select' && filter.value !== ''"
					:key="filter.key"
					:text="filter.options.find(option => option.value === filter.value)?.label ?? filter.value"
					@delete="filter.value = ''"
			/>

			<template v-else-if="filter.type === 'checkbox' && filter.values.length > 0">
				<AppliedFilterBadge
						v-for="selectedValue in filter.values"
						:key="selectedValue"
						:text="filter.options.find(option => option.value === selectedValue)?.label ?? selectedValue"
						@delete="filter.values = filter.values?.filter(value => value !== selectedValue) ?? []"
				/>
			</template>
		</template>

		<BaseButton text="Filters wissen" btn-class="minimal-transparent" @click="dismissAllFilters" />
	</div>
</template>

<script generic="T extends Record<string, unknown>" lang="ts" setup>
	import {computed} from 'vue';
	import type {FilterOptions} from '~/lib/filter/filterTypes';
	import BaseButton from '~/components/generic/buttons/BaseButton.vue';
	import AppliedFilterBadge from '~/components/generic/overview/filter/AppliedFilterBadge.vue';

	const options = defineModel<FilterOptions<T>>('options', {
		required: true
	});

	const searchActive = computed(() => options.value.search.value !== '');
	const filtersActive = computed(() => {
		return options.value.filters.some(filter => {
			if (filter.type === 'select') {
				return filter.value !== '';
			}
			if (filter.type === 'checkbox') {
				return filter.values.length > 0;
			}
			return false;
		});
	});

	const dismissAllFilters = () => {
		options.value.search.value = '';
		options.value.filters.forEach(filter => {
			if (filter.type === 'select') {
				filter.value = '';
			}
			if (filter.type === 'checkbox') {
				filter.values = [];
			}
		});
	};
</script>

<style scoped>
	.title {
		font-weight: normal;
		font-size: inherit;
		color: inherit;
		margin: 0;
		padding: 0;
	}

	.overview-applied-filters {
		display: flex;
		gap: 4px 10px;
		align-items: center;
		flex-wrap: wrap;
		max-width: 100%;

		& > * {
			flex: 0 0 auto;
		}
	}
</style>
